<template>
  <div class="jh-container">
    <div class="jh-ui-header">
      <h1>채팅 상담사별 상담통계</h1>
      <div class="is-right">
      </div>
    </div>
    <div class="jh-search-form">
      <table>
          <colgroup>
            <col width="55px">
            <col width="60px">
            <col width="70px">
            <col width="250px">
            <col width="70px">
            <col width="150px">
            <col width="150px">
            <col width="120px">
            <col>
            <col width="100px">
        </colgroup>
        <tr>
          <th><label>조회유형</label></th>
          <td>
            <v-select class="jh-form" :items="SEARCH_TY" item-value="CD" item-text="NM" placeholder="선택" v-model="SEARCH_NEWTY" @change="chkInqTY()"></v-select>
          </td>
          <th><label>조회기간</label></th>
          <td>
            <div class="jh-cols" v-if="this.SEARCH_NEWTY === 'MONTH'">                        
                <v-menu v-model="menuStartMonth" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="fromMonth" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuStartMonth = false"
                    maxlength="7" @input="fromMonthOnInput" @click:clear="months[0] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})$/, '$1-$2');"></v-text-field>
                  </template>
                  <v-date-picker class="jh-calendar" type="month" v-model="months[0]" @input="menuStartMonth = false" no-title locale="ko-KR" :max="months[1]" :day-format="mixin_getDate" @change="startMonth"></v-date-picker>
                </v-menu>
                <span class="jh-unit">~</span>                       
                <v-menu v-model="menuEndMonth" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="toMonth" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuEndMonth = false"
                    maxlength="7" @input="toMonthOnInput" @click:clear="months[1] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})$/, '$1-$2');"></v-text-field>
                  </template>
                  <v-date-picker class="jh-calendar" type="month" v-model="months[1]" @input="menuEndMonth = false" no-title locale="ko-KR" :min="months[0]" :day-format="mixin_getDate" @change="endMonth"></v-date-picker>
                </v-menu>
            </div>
            <v-text-field class="jh-form" style="width: 110px;" placeholder="조회 년도 입력" v-model="years" clearable v-else-if="this.SEARCH_NEWTY === 'YEAR'"></v-text-field>
            <div class="jh-cols" v-else>                        
                <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="from" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuStartDate = false"
                    maxlength="10" @input="fromOnInput" @click:clear="dates[0] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                  </template>
                  <v-date-picker class="jh-calendar" v-model="dates[0]" @input="menuStartDate = false" no-title locale="ko-KR" :max="dates[1]" :day-format="mixin_getDate" @change="changeDate('start')"></v-date-picker>
                </v-menu>
                <span class="jh-unit">~</span>                       
                <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="to" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuEndDate = false"
                    maxlength="10" @input="toOnInput" @click:clear="dates[1] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                  </template>
                  <v-date-picker class="jh-calendar" v-model="dates[1]" @input="menuEndDate = false" no-title locale="ko-KR" :min="dates[0]" :day-format="mixin_getDate" @change="changeDate('end')"></v-date-picker>
                </v-menu>
            </div>
          </td>
          <th><label>상담사</label></th>
          <!--
          <td>
            <v-select class="jh-form" :items="DEPT_COMBO" item-value="CD" item-text="CD_NM" placeholder="선택" v-model="ATTR_A"></v-select>
          </td>
          <td>
            <v-select class="jh-form" :items="TEAM_COMBO" item-value="CD" item-text="CD_NM" placeholder="선택" v-model="ATTR_B"></v-select>
          </td>
          <td>
            <v-select class="jh-form" :items="CNSL_COMBO" item-value="CD" item-text="CD_NM" placeholder="선택" v-model="CNSL_ID"></v-select>
          </td>
          <td>
            <v-btn class="jh-btn is-fill has-icon-only" @click="[btnUser(), showDialog('P101801')]"><i class="jh-icon-search is-white"></i></v-btn>
          </td>
          -->
          <td><v-select class="jh-form" v-model="ATTR_A" :items="DEPT_COMBO" item-value="CD" item-text="CD_NM" @change="changeDept(ATTR_A)"></v-select></td>
          <td><v-select class="jh-form" v-model="ATTR_B" :items="TEAM_COMBO" item-value="CD" item-text="CD_NM" @change="changeUser(ATTR_A, ATTR_B)"></v-select></td>
          <td><v-select class="jh-form" v-model="CNSL_ID" :items="CNSL_COMBO" item-value="USER_ID" item-text="USER_NM" ></v-select></td>
          <td><v-btn class="jh-btn is-fill has-icon-only" @click="showDialog('P101801')"><i class="jh-icon-search-sm is-white"></i></v-btn></td>
        </tr>
        <tr>
          <th><label>조회요일</label></th>
          <td colspan="3">
            <v-select
              class="jh-form is-round has-badge"
              single-line
              multiple
              v-model="CHK_NEWDAY"
              :items="CHK_DAY"
              :disabled="CHK_SHOW_DAY"
              item-value="value"
              item-text="text"
              placeholder="선택"
              @change="[chgChkDay(),chkNoDay()]"
            >
              <template v-slot:selection="{ item, index }">
                <span class="jh-badge is-light">
                  {{ item.text }}
                  <template>
                    <v-btn class="jh-btn is-sm is-icon" v-show="dropItemRemove === true" @click.stop="removeDropDay(item)"><i class="jh-icon-close-xs"></i></v-btn>
                  </template>
                </span>
              </template>
            </v-select>
          </td>
          <th><label>조회시간</label></th>
          <td colspan="4">
            <div class="jh-cols">
              <v-select
                class="jh-form is-round has-badge"
                single-line
                multiple
                v-model="CHK_NEWTIME"
                :items="CHK_TIME"
                :disabled="CHK_SHOW_TIME"
                item-value="value"
                item-text="text"
                placeholder="선택"
                @change="[chgChkTime(),chkNoTime()]"
              >
                <template v-slot:selection="{ item, index }">
                  <span class="jh-badge is-light" v-if="index < 14">
                    {{ item.text }}
                    <template>
                      <v-btn class="jh-btn is-sm is-icon" v-show="dropItemRemove === true" @click.stop="removeDropTime(item)"><i class="jh-icon-close-xs"></i></v-btn>
                    </template>
                  </span>
                  <span v-if="index === 13" class="is-multi-dot">...</span>
                </template>
              </v-select>
              <v-btn class="jh-btn is-round is-main" @click="allClick">전체선택</v-btn>
              <v-btn class="jh-btn is-round is-border-blue" @click="[allCloseClick(),chkNoTime()]">전체해제</v-btn>
              <v-btn class="jh-btn is-round is-blue" @click="selectTime">업무시간선택</v-btn>
              <v-btn class="jh-btn is-round is-border-blue" @click="selectTurn">선택반전</v-btn>
            </div>
          </td>
          <td class="has-search">
            <v-btn class="jh-btn is-search" @click="selectList">조회</v-btn>
          </td>
        </tr>
      </table>
    </div>

    <div class="jh-form-wrap">
      <div class="jh-ui-header">
        <h2>채팅 상담사별 상담통계 리스트</h2>
        <div class="is-right">
          <v-btn class="jh-btn is-light" @click="downloadExcel('userCnslTable', '채팅 상담사별 상담통계', '상담사별 통계')">엑셀다운로드</v-btn>
        </div>
      </div>
      <data-tables
      id="userCnslTable"
      dataTableClass=""
      :data-table-options="dataTableOptions"
      :paginationOptions="paginationOptions"
      @click:row="rowClick"
      >
        <template v-slot:header>
          <thead class="v-data-table-header">
            <tr>
              <th rowspan="2" width="40px"><span>No</span></th>
              <th rowspan="2" width="120px"><span>상담직원/사번</span></th>
              <th rowspan="2"><span>총상담<br/>처리</span></th>
              <th rowspan="2"><span>배정상담</span></th>
              <th rowspan="2"><span>고객대기중 포기</span></th>
              <th rowspan="2"><span>보낸전달</span></th>
              <th rowspan="2"><span>받은전달</span></th>
              <th rowspan="2"><span>상담응대율(%)</span></th>
              <th colspan="10"><span>상태별 시간</span></th>
              <th rowspan="2"><span>채팅근무시간</span></th>
              <th colspan="4"><span>합계</span></th>
              <th colspan="3"><span>평균</span></th>
            </tr>
            <tr>
              <th><span>채팅 ON</span></th>
              <th><span>채팅 OFF</span></th>
              <th><span>업무확인</span></th>
              <th><span>면담/회의</span></th>
              <th><span>점심시간</span></th>
              <th><span>코칭/교육</span></th>
              <th><span>이석중<br/>( 5분 ↓)</span></th>
              <th><span>이석중<br/>( 5분 ↑)</span></th>
              <th><span>업무 TEST</span></th>
              <th><span>배정중단</span></th>
              <th><span>처리시간</span></th>
              <th><span>채팅시간</span></th>
              <th><span>후처리<br/>시간</span></th>
              <th><span>이석시간</span></th>
              <th><span>처리시간</span></th>
              <th><span>채팅시간</span></th>
              <th><span>후처리<br/>시간</span></th>
            </tr>
          </thead>
        </template>
        <template slot="body.append" v-if="gridDataText.length > 0">
          <!-- 
        { text: "채팅ON", value: "S_CHATON", align: "center",  },
        { text: "채팅OFF", value: "S_CHATOFF", align: "center",  },
        { text: "업무확인", value: "S_ETC", align: "center", },  //TODO
        { text: "면담/회의", value: "S_MEETING", align: "center",  },
        { text: "점심시간", value: "S_MEAL", align: "center",  },
        { text: "코칭/교육", value: "S_EDU", align: "center", },  //TODO
        { text: "이석중 ( 5분 ↓)", value: "S_ADDWORK", align: "center", },  //TODO
        { text: "이석중 ( 5분 ↑)", value: "S_REST", align: "center",  },
        { text: "업무 TEST", value: "S_TEST", align: "center", },  //TODO
        { text: "배정중단", value: "S_CHATSTOP", align: "center",  },

        { text: "근무시간", value: "S_WORKDAYTIME", align: "center",  },
        { text: "합계처리시간", value: "SUM_CHATTING_AFTER_TREATMENT", align: "center", },
        { text: "합계채팅시간", value: "SUM_CHATTING", align: "center",  },
        { text: "합계후처리시간", value: "SUM_AFTER_TREATMENT", align: "center", },
        { text: "이석시간", value: "S_LEAVEDAYTIME", align: "center", },  //TODO
        { text: "평균처리시간", value: "AVG_CHATTING_AFTER_TREATMENT", align: "center", },
        { text: "평균상담시간", value: "AVG_CHATTING", align: "center",  },
        { text: "평균후처리시간", value: "AVG_AFTER_TREATMENT", align: "center", },

            <th colspan="6">합계</th>
            <td align="center"> {{ mixin_date_sum_field( this.gridDataText, "S_CHATON" ) }} </td>
            <td align="center"> {{ mixin_date_sum_field( this.gridDataText, "S_CHATOFF" ) }} </td>
            <td align="center"> {{ mixin_date_sum_field( this.gridDataText, "S_REST" ) }} </td>
            <td align="center"> {{ mixin_date_sum_field( this.gridDataText, "SUM_CHATTING" ) }} </td>
            <td align="center"> {{ mixin_date_sum_field( this.gridDataText, "SUM_AFTER_TREATMENT" ) }} </td>
            <td align="center"> {{ mixin_date_sum_field( this.gridDataText, "SUM_CHATTING_AFTER_TREATMENT" ) }} </td>
            <td align="center"> {{ mixin_date_avg_field( this.gridDataText, "AVG_CHATTING" ) }} </td>
            <td align="center"> {{ mixin_date_avg_field( this.gridDataText, "AVG_AFTER_TREATMENT" ) }} </td>
            <td align="center"> {{ mixin_date_avg_field( this.gridDataText, "AVG_CHATTING_AFTER_TREATMENT" ) }} </td>
          </tr> -->
          <tr class="jh-grid-footer is-sum">
            <th colspan="2">합계</th>
            <td align="center"> {{ mixin_sum_field( this.gridDataText, "NUMBER_TOT_PROGRESS" ) }} </td>
            <td align="center"> {{ mixin_sum_field( this.gridDataText, "NUMBER_ASSIGNMENT" ) }} </td>
            <td align="center"> {{ mixin_sum_field( this.gridDataText, "NUMBER_CUSTOMER_GIVE_UP_92" ) }} </td>
            <td align="center"> {{ mixin_sum_field( this.gridDataText, "SND_NUMBER_RELAY" ) }} </td>
            <td align="center"> {{ mixin_sum_field( this.gridDataText, "RCV_NUMBER_RELAY" ) }} </td>
            <td align="center"> {{ sumRateResponse }} </td>

            <td align="center"> {{ mixin_date_sum_field( this.gridDataText, "S_CHATON" ) }} </td>
            <td align="center"> {{ mixin_date_sum_field( this.gridDataText, "S_CHATOFF" ) }} </td>
            <td align="center"> {{ mixin_date_sum_field( this.gridDataText, "S_ETC" ) }} </td>
            <td align="center"> {{ mixin_date_sum_field( this.gridDataText, "S_MEETING" ) }} </td>
            <td align="center"> {{ mixin_date_sum_field( this.gridDataText, "S_MEAL" ) }} </td>
            <td align="center"> {{ mixin_date_sum_field( this.gridDataText, "S_EDU" ) }} </td>
            <td align="center"> {{ mixin_date_sum_field( this.gridDataText, "S_ADDWORK" ) }} </td>
            <td align="center"> {{ mixin_date_sum_field( this.gridDataText, "S_REST" ) }} </td>
            <td align="center"> {{ mixin_date_sum_field( this.gridDataText, "S_TEST" ) }} </td>
            <td align="center"> {{ mixin_date_sum_field( this.gridDataText, "S_CHATSTOP" ) }} </td>
            <td align="center"> {{ mixin_date_sum_field( this.gridDataText, "S_WORKDAYTIME" ) }} </td>
            <td align="center"> {{ mixin_date_sum_field( this.gridDataText, "SUM_CHATTING_AFTER_TREATMENT" ) }} </td>
            <td align="center"> {{ mixin_date_sum_field( this.gridDataText, "SUM_CHATTING" ) }} </td>
            <td align="center"> {{ mixin_date_sum_field( this.gridDataText, "SUM_AFTER_TREATMENT" ) }} </td>
            <td align="center"> {{ mixin_date_sum_field( this.gridDataText, "S_LEAVEDAYTIME" ) }} </td>
            <td align="center"> {{ mixin_date_sum_field( this.gridDataText, "AVG_CHATTING_AFTER_TREATMENT" ) }} </td>
            <td align="center"> {{ mixin_date_sum_field( this.gridDataText, "AVG_CHATTING" ) }} </td>
            <td align="center"> {{ mixin_date_sum_field( this.gridDataText, "AVG_AFTER_TREATMENT" ) }} </td>
          </tr>
        </template>
      </data-tables>
    </div>

    <!-- 상담사조회 -->
    <v-dialog max-width="1000" v-model="DialogP101801">
      <dialog-P101801
      headerTitle="상담사조회"
      @hide="hideDialog('P101801')"
      :userInfo="userInfo"
      @input="userInfo = $event"
      @selectUser="[hideDialog('P101801'), setUserData()]"
      ></dialog-P101801>
      <template v-slot:body></template>
    </v-dialog>
  </div>
</template>

<script>
import api from "@/store/apiUtil.js";
import { mixin } from "@/mixin/mixin.js";
import dataTables from "@/components/DataTables";
import DialogP101801 from "./P101801.vue";

export default {
  name: "MENU_E020501", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components: {
    DialogP101801,
    dataTables
  },
  data() {
    return {
      DialogP101801: false,
      userInfo: {},
      menuStartDate: false,
      menuEndDate: false,
      menuStartMonth: false,
      menuEndMonth: false,
      dropItemRemove: true,
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      years: moment().format("YYYY"),
      sumRateResponse: 0,
      sumTotProgress: 0,
      sumAssignment: 0,
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      CHK_SHOW_TIME: true,
      CHK_SHOW_DAY: true,
      fromMonth: moment().format("YYYY-MM"),
      toMonth: moment().format("YYYY-MM"),
      months: [
        new Date().toISOString().substr(0, 7),
        new Date().toISOString().substr(0, 7)
      ],
      gridDataHeaders: [
        { text: "No", align: "center", value: "index", width: "50px",  },
        // { text: "회사명", value: "COMPANY_NM", align: "center",  },
        { text: "상담사명/사번", value: "USER_ID", align: "center",  },
        { text: "상담처리", value: "NUMBER_TOT_PROGRESS", align: "center",  },
        // { text: "성명", value: "USER_NM", align: "center",  },
        // { text: "닉네임", value: "USER_NICK", align: "center",  },
        // { text: "소속", value: "ATTR_DIV_NM_AC", align: "center",  },
        { text: "배정", value: "NUMBER_ASSIGNMENT", align: "center",  },
        { text: "고객대기중 포기", value: "NUMBER_CUSTOMER_GIVE_UP_92", align: "center", },
        { text: "보낸전달", value: "SND_NUMBER_RELAY", align: "center",  },
        { text: "받은전달", value: "RCV_NUMBER_RELAY", align: "center",  },
        { text: "상담응대율(%)", value: "RATE_RESPONSE", align: "center",  },
        // { text: "상담", value: "NUMBER_PROGRESS", align: "center", },
        { text: "채팅ON", value: "S_CHATON", align: "center",  },
        { text: "채팅OFF", value: "S_CHATOFF", align: "center",  },
        { text: "업무확인", value: "S_ETC", align: "center", },  //TODO
        { text: "면담/회의", value: "S_MEETING", align: "center",  },
        { text: "점심시간", value: "S_MEAL", align: "center",  },
        { text: "코칭/교육", value: "S_EDU", align: "center", },  //TODO
        { text: "이석중 ( 5분 ↓)", value: "S_ADDWORK", align: "center", },  //TODO
        { text: "이석중 ( 5분 ↑)", value: "S_REST", align: "center",  },
        { text: "업무 TEST", value: "S_TEST", align: "center", },  //TODO
        { text: "배정중단", value: "S_CHATSTOP", align: "center",  },

        { text: "근무시간", value: "S_WORKDAYTIME", align: "center",  },
        // { text: "추가업무", value: "S_ADDWORK", align: "center",  },
        { text: "합계처리시간", value: "SUM_CHATTING_AFTER_TREATMENT", align: "center", },
        { text: "합계채팅시간", value: "SUM_CHATTING", align: "center",  },
        { text: "합계후처리시간", value: "SUM_AFTER_TREATMENT", align: "center", },
        { text: "이석시간", value: "S_LEAVEDAYTIME", align: "center", },  //TODO
        { text: "평균처리시간", value: "AVG_CHATTING_AFTER_TREATMENT", align: "center", },
        { text: "평균상담시간", value: "AVG_CHATTING", align: "center",  },
        { text: "평균후처리시간", value: "AVG_AFTER_TREATMENT", align: "center", },
      ],
      gridDataText: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      totalVisible: 10,

      dataTableOptions: {
          fixedHeader: true,
          hideDefaultFooter: true,
          hideDefaultHeader: true,
          height: '575px',
          itemKey: 'index',
          itemsPerPage: 50,
          items:[],
          noDataText: "조회된 결과가 없습니다.",
          page: 1,
          singleSelect: true,
          itemClass: 'isActiveRow',
      },
      paginationOptions: {
          totalVisible: 10
      },

      ASP_CUST_KEY: this.$store.getters["userStore/GE_USER_ROLE"].company,

      SEARCH_TY: [
        {
          CD: "TIME",
          NM: "시간"
        },
        {
          CD: "DAY",
          NM: "일",
        },
        {
          CD: "MONTH",
          NM: "월",
        },
        {
          CD: "YEAR",
          NM: "년",
        },
        {
          CD: "DAY_OF_THE_WEEK",
          NM: "요일",
        },
      ],

      ASP_SENDER_KEY: [
        {
          CD: "",
          NM: "전체",
        },
      ],

      INQ_TYP_1: [
        {
          CD: "",
          CD_NM: "전체",
        },
      ],

      INQ_TYP_2: [
        {
          CD: "",
          CD_NM: "전체",
        },
      ],

      DEPT_COMBO: [
        {
          CD: "",
          CD_NM: "전체",
        },
      ],

      TEAM_COMBO: [
        {
          CD: "",
          CD_NM: "전체",
        },
      ],

      CNSL_COMBO: [
        {
          CD: "",
          CD_NM: "전체",
        },
      ],

      USER_ATTR_D: [
        {
          CD: "",
          CD_NM: "전체",
        },
      ],

      ATRT_USER_GP: [
        {
          CD: "",
          CD_NM: "전체",
        },
      ],

      CHK_DAY: [
        {
          text: "일",
          value: "1",
        },
        {
          text: "월",
          value: "2",
        },
        {
          text: "화",
          value: "3",
        },
        {
          text: "수",
          value: "4",
        },
        {
          text: "목",
          value: "5",
        },
        {
          text: "금",
          value: "6",
        },
        {
          text: "토",
          value: "7",
        },
      ],

      CHK_TIME: [
        {
          text: "00시",
          selected: true,
          value: "00",
        },
        {
          text: "01시",
          selected: true,
          value: "01",
        },
        {
          text: "02시",
          selected: true,
          value: "02",
        },
        {
          text: "03시",
          selected: true,
          value: "03",
        },
        {
          text: "04시",
          selected: true,
          value: "04",
        },
        {
          text: "05시",
          selected: true,
          value: "05",
        },
        {
          text: "06시",
          selected: true,
          value: "06",
        },
        {
          text: "07시",
          selected: true,
          value: "07",
        },
        {
          text: "08시",
          selected: true,
          value: "08",
        },
        {
          text: "09시",
          selected: true,
          value: "09",
        },
        {
          text: "10시",
          selected: true,
          value: "10",
        },
        {
          text: "11시",
          selected: true,
          value: "11",
        },
        {
          text: "12시",
          selected: true,
          value: "12",
        },
        {
          text: "13시",
          selected: true,
          value: "13",
        },
        {
          text: "14시",
          selected: true,
          value: "14",
        },
        {
          text: "15시",
          selected: true,
          value: "15",
        },
        {
          text: "16시",
          selected: true,
          value: "16",
        },
        {
          text: "17시",
          selected: true,
          value: "17",
        },
        {
          text: "18시",
          selected: true,
          value: "18",
        },
        {
          text: "19시",
          selected: true,
          value: "19",
        },
        {
          text: "20시",
          selected: true,
          value: "20",
        },
        {
          text: "21시",
          selected: true,
          value: "21",
        },
        {
          text: "22시",
          selected: true,
          value: "22",
        },
        {
          text: "23시",
          selected: true,
          value: "23",
        },
      ],

      ASP_NEWCUST_KEY: "001",
      SEARCH_NEWTY: "DAY",
      ASP_NEWSENDER_KEY: "",
      INQ_NEWTYP_1: "",
      INQ_NEWTYP_2: "",
      USER_ID: "",
      USER_NM: "",
      USER_NICK: "",
      ATTR_A: "",
      ATTR_B: "",
      CNSL_ID: "",
      USER_NEWATTR_D: "",
      ATRT_NEWUSER_GP: "",

      CHK_NEWDAY: ["1", "2", "3", "4", "5", "6", "7"],
      CHK_NEWTIME: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],

      ROW_DATA:{},
    };
  },

  created() {
    this.DEPT_COMBO = [{CD: '', CD_NM: '전체'}];
    this.TEAM_COMBO = [{CD: '', CD_NM: '전체'}];
    this.CNSL_COMBO = [{USER_ID: '', USER_NM: '전체'}];
    this.getAspAuth();
    this.dataTableOptions.headers = this.gridDataHeaders;
    this.dataTableOptions.items = this.gridDataText;
    this.chnASP();
    this.getAttrList();
  },

  methods: {
    fromOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.from) && moment(this.from).isValid()) {
        // 종료일자보다 이후를 입력했으면 종료일자로 변경
        if (moment(this.from).isAfter(this.dates[1])) {
          this.$nextTick(() => {
            this.from = this.dates[1];
          });
        }

        this.$nextTick(() => {
          this.dates[0] = this.from;
          // datepick 다시 랜더링
          this.menuStartDate = false;
          this.menuStartDate = true;
        });
      }
    },
    startDate(e) {
      this.from = e;
    },
    toOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.to) && moment(this.to).isValid()) {
        // 시작일자보다 이전을 입력했으면 시작일자로 변경
        if (moment(this.to).isBefore(this.dates[0])) {
          this.$nextTick(() => {
            this.to = this.dates[0];
          });
        }

        this.$nextTick(() => {
          this.dates[1] = this.to;
          // datepick 다시 랜더링
          this.menuEndDate = false;
          this.menuEndDate = true;
        });
      }
    },
    endDate(e) {
      this.to = e;
    },
    fromMonthOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}$/.test(this.fromMonth) && moment(this.fromMonth).isValid()) {
        // 종료일자보다 이후를 입력했으면 종료일자로 변경
        if (moment(this.fromMonth).isAfter(this.months[1])) {
          this.$nextTick(() => {
            this.fromMonth = this.months[1];
          });
        }

        this.$nextTick(() => {
          this.months[0] = this.fromMonth;
          // datepick 다시 랜더링
          this.menuStartMonth = false;
          this.menuStartMonth = true;
        });
      }
    },
    startMonth(e) {
      this.fromMonth = e;
    },
    toMonthOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}$/.test(this.toMonth) && moment(this.toMonth).isValid()) {
        // 시작일자보다 이전을 입력했으면 시작일자로 변경
        if (moment(this.toMonth).isBefore(this.months[0])) {
          this.$nextTick(() => {
            this.toMonth = this.months[0];
          });
        }

        this.$nextTick(() => {
          this.months[1] = this.toMonth;
          // datepick 다시 랜더링
          this.menuEndMonth = false;
          this.menuEndMonth = true;
        });
      }
    },
    endMonth(e) {
      this.toMonth = e;
    },

    removeDropDay(item) {
      this.CHK_NEWDAY.splice((item.value-1), 1);

      let DayArray = this.CHK_NEWDAY;
      this.CHK_NEWDAY = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]
      for(let i = 0; i < DayArray.length; i++){
        if(DayArray[i] != "" || DayArray[i] != null){
          let m = parseInt(DayArray[i]);
          this.CHK_NEWDAY[m-1] = DayArray[i];
        }
      }

      this.chkNoDay();
    },

    removeDropTime(item) {
      this.CHK_NEWTIME.splice(parseInt(item.value), 1);

      let TimeArray = this.CHK_NEWTIME;
      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]
      for(let i = 0; i < TimeArray.length; i++){
        if(TimeArray[i] != "" || TimeArray[i] != null){
          let m = parseInt(TimeArray[i]);
          this.CHK_NEWTIME[m] = TimeArray[i];
        }
      }

      this.chkNoTime();
    },

    //상담사 콤보박스 조회
    async chnASP() {
      let get_user_role = this.$store.getters["userStore/GE_USER_ROLE"];

      this.userInfo.USER_ATTR_A = get_user_role.USER_ATTR_A;
      this.userInfo.USER_ATTR_B = get_user_role.USER_ATTR_B;
      this.userInfo.USER_ID = get_user_role.userId;
      this.userInfo.ASP_CUST_KEY = this.ASP_NEWCUST_KEY;

      this.ASP_SENDER_KEY = [{ CD: "", NM: "전체"}];
      let sender = await this.mixin_channel_get(this.ASP_NEWCUST_KEY);
      this.ASP_SENDER_KEY = [...this.ASP_SENDER_KEY, ...sender];
    },

    //사용자 소속 콤보박스 생성
    getAttrDiv(attr) {
        api.post("api/palette/common/get-attr-div/list",   //api url입력
        {
            ATTR_CD: attr,
            ATTR_DIV_CD: '',
        },
        {head: {
            "SERVICE" : "palette.common.get-attr-div",
            "METHOD" : "list",
            "TYPE" : "BIZ_SERVICE",
        }})
        .then((response) => {
            if (attr == 'A'){
            this.DEPT_COMBO = [...this.DEPT_COMBO, ...response.data.DATA];
            } else if (attr == "B") {
            this.TEAM_COMBO = [...this.TEAM_COMBO, ...response.data.DATA];
            }
        })
        .catch((err) => {
            alert(err);
        })
    },

    //상담사 권한 콤보박스 생성
    getAspAuth() {
      api
        .post(
          "api/statistics/chat/counsel-by-agent/combobox/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY:
              this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
          },
          {
            head: {
              SERVICE: "statistics.chat.counsel-by-agent.combobox",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          console.log(response.data.DATA);
          this.ATRT_USER_GP = [...this.ATRT_USER_GP, ...response.data.DATA];
        })
        .catch((err) => {
          alert(err);
        });
    },

    allClick() {
      this.CHK_NEWTIME = [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ];
    },

    allCloseClick() {
      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];
    },

    async changeDept(attrA){
        this.TEAM_COMBO = await this.mixin_get_attr_by_attr('', attrA);

        this.CNSL_ID = '';
        this.ATTR_B = '';
    },
    async changeUser(attrA, attrB){
        this.CNSL_COMBO = await this.mixin_get_user_by_attr(attrA, attrB);
        this.CNSL_ID = '';
    },

    async getAttrList() {
        this.DEPT_COMBO = await this.mixin_get_attr_by_attr('****', '');
        this.TEAM_COMBO = [{CD: '', CD_NM: '전체'}];
        this.CNSL_COMBO = [{USER_ID: '', USER_NM: '전체'}];

        this.ATTR_A = '';
        this.ATTR_B = '';
    },
    downloadExcel(tableId, fileNm, sheetNm){
      //number,date,time,string - n,d,t,s
        var exceltypeArr = {};
        exceltypeArr[0] = "n";
        exceltypeArr[1] = "s";
        exceltypeArr[2] = "n";
        exceltypeArr[3] = "n";
        exceltypeArr[4] = "n";
        exceltypeArr[5] = "n";
        exceltypeArr[6] = "n";
        exceltypeArr[7] = "n";
        exceltypeArr[8] = "t";
        exceltypeArr[9] = "t";
        exceltypeArr[10] = "t";
        exceltypeArr[11] = "t";
        exceltypeArr[12] = "t";
        exceltypeArr[13] = "t";
        exceltypeArr[14] = "t";
        exceltypeArr[15] = "t";
        exceltypeArr[16] = "t";
        exceltypeArr[17] = "t";
        exceltypeArr[18] = "t";
        exceltypeArr[19] = "t";
        exceltypeArr[20] = "t";
        exceltypeArr[21] = "t";
        exceltypeArr[22] = "t";
        exceltypeArr[23] = "t";
        exceltypeArr[24] = "t";
        exceltypeArr[25] = "t";
         

        //엑셀다운로드
        this.table_id   = tableId;
        this.file_name  = fileNm;
        this.sheet_name = sheetNm;
        this.mixin_common_exportExcel(exceltypeArr);
    },
    async selectTime() {
      if (this.ASP_NEWCUST_KEY == "" || this.ASP_NEWCUST_KEY == undefined) {
        this.showAlert("회사를 먼저 선택해주세요.");
        return;
      }
      await api
        .post(
          "api/statistics/chat/common/start-job-time/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY:
              this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
          },
          {
            head: {
              SERVICE: "statistics.chat.common.start-job-time",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          var ST_TIME = response.data.DATA[0].WORK_START_TIME;

          var ST_HOUR = ST_TIME.substr(0, 2);
          this.ST_JOB_TIME = ST_HOUR;
        })
        .catch((err) => {
          alert(err);
        });

      await api
        .post(
          "api/statistics/chat/common/end-job-time/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY:
              this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
          },
          {
            head: {
              SERVICE: "statistics.chat.common.end-job-time",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          var END_TIME = response.data.DATA[0].WORK_END_TIME;

          var END_HOUR = END_TIME.substr(0, 2);
          this.END_JOB_TIME = END_HOUR;
        })
        .catch((err) => {
          alert(err);
        });

      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];

      for (
        var i = parseInt(this.ST_JOB_TIME);
        i <= parseInt(this.END_JOB_TIME);
        i++
      ) {
        if (i < 10) {
          let DB_TIME = "0" + i;
          this.CHK_NEWTIME[i] = DB_TIME;
        } else {
          this.CHK_NEWTIME[i] = i + "";
        }
      }
    },

    selectTurn() {
      var NEWTIME = [];

      for (var i = 0; i < 24; i++) {
        NEWTIME[i] = this.CHK_NEWTIME[i];
        if (i < 10) {
          if (NEWTIME[i] != "") {
            NEWTIME[i] = "";
          } else if (NEWTIME[i] == "") {
            let DB_TIME = "0" + i;
            NEWTIME[i] = DB_TIME;
          }
        } else {
          if (NEWTIME[i] != "") {
            NEWTIME[i] = "";
          } else if (NEWTIME[i] == "") {
            NEWTIME[i] = i + "";
          }
        }
      }
      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];
      this.CHK_NEWTIME = NEWTIME;
    },

    selectList() {
      if (this.ASP_NEWCUST_KEY == "" || this.ASP_NEWCUST_KEY == undefined) {
        this.showAlert("회사를 먼저 선택해주세요.");
        return;
      }
      
      let DT1 = this.dates[0];
      let DT1_Array = DT1.split("-");
      var YEAR1 = this.years;
      var MONTH1 = DT1_Array[0] + DT1_Array[1] + "01";
      var ST_DATE = DT1_Array[0] + DT1_Array[1] + DT1_Array[2];
      let DT2 = this.dates[1];
      let DT2_Array = DT2.split("-");
      var MONTH2 = DT2_Array[0] + DT2_Array[1] + "31";
      var END_DATE = DT2_Array[0] + DT2_Array[1] + DT2_Array[2];

      if (this.SEARCH_NEWTY == "MONTH"){
        let temp_ST = this.months[0].split("-")
        let temp_END = this.months[1].split("-")

        ST_DATE = temp_ST[0] + temp_ST[1] + "01";
        END_DATE = temp_END[0] + temp_END[1] + "31";
      }else if (this.SEARCH_NEWTY == "YEAR"){
        if (this.years == "" || this.years == undefined) {
          this.showAlert("조회 년도를 입력해주세요.");
          return;
        }
        ST_DATE = YEAR1 + "0101"
        END_DATE = YEAR1 + "1231"
      }

      api
        .post(
          "api/statistics/chat/counsel-by-agent/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
            SEARCH_TYPE: this.SEARCH_NEWTY,
            SEARCH_FROM: ST_DATE,
            SEARCH_TO: END_DATE,
            SEARCH_MONTH_FROM: MONTH1,
            SEARCH_MONTH_TO: MONTH2,
            SEARCH_YEAR: YEAR1,
            CHK_DAY_OF_THE_WEEK_1: this.CHK_NEWDAY[0],
            CHK_DAY_OF_THE_WEEK_2: this.CHK_NEWDAY[1],
            CHK_DAY_OF_THE_WEEK_3: this.CHK_NEWDAY[2],
            CHK_DAY_OF_THE_WEEK_4: this.CHK_NEWDAY[3],
            CHK_DAY_OF_THE_WEEK_5: this.CHK_NEWDAY[4],
            CHK_DAY_OF_THE_WEEK_6: this.CHK_NEWDAY[5],
            CHK_DAY_OF_THE_WEEK_7: this.CHK_NEWDAY[6],
            SEARCH_TIME_0: this.CHK_NEWTIME[0],
            SEARCH_TIME_1: this.CHK_NEWTIME[1],
            SEARCH_TIME_2: this.CHK_NEWTIME[2],
            SEARCH_TIME_3: this.CHK_NEWTIME[3],
            SEARCH_TIME_4: this.CHK_NEWTIME[4],
            SEARCH_TIME_5: this.CHK_NEWTIME[5],
            SEARCH_TIME_6: this.CHK_NEWTIME[6],
            SEARCH_TIME_7: this.CHK_NEWTIME[7],
            SEARCH_TIME_8: this.CHK_NEWTIME[8],
            SEARCH_TIME_9: this.CHK_NEWTIME[9],
            SEARCH_TIME_10: this.CHK_NEWTIME[10],
            SEARCH_TIME_11: this.CHK_NEWTIME[11],
            SEARCH_TIME_12: this.CHK_NEWTIME[12],
            SEARCH_TIME_13: this.CHK_NEWTIME[13],
            SEARCH_TIME_14: this.CHK_NEWTIME[14],
            SEARCH_TIME_15: this.CHK_NEWTIME[15],
            SEARCH_TIME_16: this.CHK_NEWTIME[16],
            SEARCH_TIME_17: this.CHK_NEWTIME[17],
            SEARCH_TIME_18: this.CHK_NEWTIME[18],
            SEARCH_TIME_19: this.CHK_NEWTIME[19],
            SEARCH_TIME_20: this.CHK_NEWTIME[20],
            SEARCH_TIME_21: this.CHK_NEWTIME[21],
            SEARCH_TIME_22: this.CHK_NEWTIME[22],
            SEARCH_TIME_23: this.CHK_NEWTIME[23],
            DEPT_COMBO: this.ATTR_A,
            TEAM_COMBO: this.ATTR_B,
            //CNSL_COMBO: this.CNSL_ID,
            CNSL_COMBO: '90108',
            USER_ATTR_C: '',
            USER_ATTR_D: this.USER_NEWATTR_D,
            USER_ID: this.CNSL_ID,
            USER_NM: this.USER_NM,
            USER_NICK: this.USER_NICK,
            ATRT_GROUP_ID: this.ATRT_NEWUSER_GP,
            ASP_SENDER_KEY: this.ASP_NEWSENDER_KEY,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY:
              this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
          },
          {
            head: {
              SERVICE: "statistics.chat.counsel-by-agent",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
              ROW_CNT: "200",
              PAGES_CNT: "1",
            },
          }
        )
        .then((response) => {
          console.log(response.data.DATA);
          this.gridDataText = response.data.DATA;
          for (var i = 0; i < this.gridDataText.length; i++) {
            //인덱스처리
            this.gridDataText[i]["index"] = i + 1;

            
            let s_LEAVEDAYTIME = "00:00:00";
            let s_WORKDAYTIME = "00:00:00";
            
            //이석시간
            s_LEAVEDAYTIME = this.date_sum_object(this.gridDataText[i], ["S_ETC", "S_MEETING", "S_MEAL", "S_EDU", "S_ADDWORK", "S_REST", "S_TEST", "S_CHATSTOP"]);

            //근무시간 -> 채팅온 + 배정중단
            //s_WORKDAYTIME = this.date_sum_two(s_LEAVEDAYTIME, this.gridDataText[i]["S_CHATON"]);
            s_WORKDAYTIME = this.date_sum_two(this.gridDataText[i]["S_CHATSTOP"], this.gridDataText[i]["S_CHATON"]);

            this.gridDataText[i]["S_LEAVEDAYTIME"] = s_LEAVEDAYTIME;
            this.gridDataText[i]["S_WORKDAYTIME"] = s_WORKDAYTIME;

            //소수점2째자리 처리
            if(this.gridDataText[i]["RATE_RESPONSE"] != ''){
              this.gridDataText[i]["RATE_RESPONSE"] = parseFloat(this.gridDataText[i]["RATE_RESPONSE"]).toFixed(2);
            }
            

          }
          //합계데이터 처리
          this.sumAssignment = parseInt(this.mixin_sum_field( this.gridDataText, "NUMBER_ASSIGNMENT"));
          this.sumTotProgress = parseInt(this.mixin_sum_field( this.gridDataText, "NUMBER_TOT_PROGRESS" ));
          this.sumRateResponse = (this.sumTotProgress > 0)? (parseInt(this.sumAssignment) / parseInt(this.sumTotProgress) * 100).toFixed(2) : 0;

          this.page = 1;
          this.dataTableOptions.items = this.gridDataText;
        })
        .catch((err) => {
          alert(err);
        });
    },

    showDialog(menu) {
    //   if (this.ASP_NEWCUST_KEY == "" || this.ASP_NEWCUST_KEY == undefined) {
    //     this.showAlert("회사를 먼저 선택해주세요.");
    //     return;
    //   }
      this[`Dialog${menu}`] = true;
    },
    hideDialog(menu) {
      this[`Dialog${menu}`] = false;
    },

    initUser() {
      this.USER_ID = "";
      this.USER_NM = "";
      this.USER_NICK = "";
    },

    btnUser() {
      var user = {
        ASP_CUST_KEY: this.ASP_NEWCUST_KEY,
        USER_ID: this.CNSL_ID,
        USER_NM: this.USER_NM,
        USER_NICK: this.USER_NICK,
      };
      this.userInfo = user;
    },

    async setUserData(){
        this.TEAM_COMBO = await this.mixin_get_attr_by_attr('', this.userInfo.USER_ATTR_A);
        this.CNSL_COMBO = await this.mixin_get_user_by_attr(this.userInfo.USER_ATTR_A, this.userInfo.USER_ATTR_B);

        this.ATTR_A = this.userInfo.USER_ATTR_A
        this.ATTR_B = this.userInfo.USER_ATTR_B
        this.CNSL_ID = this.userInfo.USER_ID

    },

    //Table row 클릭이벤트
    rowClick(item, row) {
      this.ROW_DATA = item;
      //console.log("item",item);
    },

    isActiveRow(item){
      const activeClass = item.USER_ID === this.ROW_DATA.USER_ID ? 'is-active' : '';
      return activeClass;
    },

    showAlert(msg) {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: msg,
        iconClass: "jh-alert-icon is-warning",
        type: "default",
      });
    },

    chgChkTime(){
      let TimeArray = this.CHK_NEWTIME;
      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]
      for(let i = 0; i < TimeArray.length; i++){
        if(TimeArray[i] != "" || TimeArray[i] != null){
          let m = parseInt(TimeArray[i]);
          this.CHK_NEWTIME[m] = TimeArray[i];
        }
      }
     this.CHK_NEWTIME.splice(24,1);
    },

    chgChkDay(){
      let DayArray = this.CHK_NEWDAY;
      this.CHK_NEWDAY = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]
      for(let i = 0; i < DayArray.length; i++){
        if(DayArray[i] != "" || DayArray[i] != null){
          let m = parseInt(DayArray[i])-1;
          this.CHK_NEWDAY[m] = DayArray[i];
        }
      }
     this.CHK_NEWDAY.splice(7,1);
    },

    chkInqTY(){
      if (this.SEARCH_NEWTY == "TIME"){
        this.CHK_SHOW_TIME = false
        this.CHK_SHOW_DAY = true
        let TIME_CNT = 0;
        for (let i = 0; i<this.CHK_NEWTIME.length;i++){
          if (this.CHK_NEWTIME[i] != ""){
            TIME_CNT = TIME_CNT + 1;
          }
        }
        if (TIME_CNT == 0){
          this.showAlert("조회 유형이 [시간]일 때<br>시간 조회조건이 하나 이상 필요합니다.<br><br>자동으로 [09시]를 선택합니다.");
          this.CHK_NEWTIME = [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "09",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ]
        }
      } else if (this.SEARCH_NEWTY == "DAY_OF_THE_WEEK"){
        this.CHK_SHOW_TIME = true
        this.CHK_SHOW_DAY = false
        let DAY_CNT = 0;
        for (let i = 0; i<this.CHK_NEWDAY.length;i++){
          if (this.CHK_NEWDAY[i] != ""){
            DAY_CNT = DAY_CNT + 1;
          }
        }
        if (DAY_CNT == 0){
          this.showAlert("조회 유형이 [요일]일 때<br>요일 조회조건이 하나 이상 필요합니다.<br><br>자동으로 [월요일]를 선택합니다.");
          this.CHK_NEWDAY = [
            "",
            "2",
            "",
            "",
            "",
            "",
            "",
          ]
        }
      }else{
        this.CHK_SHOW_TIME = true
        this.CHK_SHOW_DAY = true
      }
      console.log(`chkInqTY call this.CHK_SHOW_TIME : ${this.CHK_SHOW_TIME} , this.CHK_SHOW_DAY : ${this.CHK_SHOW_DAY}`)
    },



    chkNoDay(){
      let DAY_CNT = 0;
      for (let i = 0; i<this.CHK_NEWDAY.length;i++){
        if (this.CHK_NEWDAY[i] != ""){
          DAY_CNT = DAY_CNT + 1;
        }
      }
      if (DAY_CNT == 0){
        if (this.SEARCH_NEWTY == "DAY_OF_THE_WEEK"){
          this.showAlert("조회 유형이 [요일]일 때<br>요일 조회조건이 하나 이상 필요합니다.<br><br>자동으로 [월요일]를 선택합니다.");
          this.CHK_NEWDAY = [
            "",
            "2",
            "",
            "",
            "",
            "",
            "",
          ]
        }
      }
    },

    date_sum_object(obj, keyList){
      if(Object.keys(obj).length == 0 || keyList.length < 1){
        return "00:00:00";
      }

      let sum = 0;
      for (let i = 0; i < keyList.length; i++) {
        if (obj[keyList[i]] !== "0") {
          sum =
            sum +
            obj[keyList[i]]
              .split(":")
              .reverse()
              .reduce((prev, curr, i) => prev + curr * Math.pow(60, i), 0);
        }
      }

      let return_data = "00:00:00";
      if (sum !== 0) {
        return_data = this.mixin_seconds_toHHMMSS(sum);
      }

      return return_data;
    },

    date_sum_two(sumTime1, sumTime2){
      let sum = 0;

      sum =
        sumTime1
          .split(":")
          .reverse()
          .reduce((prev, curr, i) => prev + curr * Math.pow(60, i), 0)
        +
        sumTime2
          .split(":")
          .reverse()
          .reduce((prev, curr, i) => prev + curr * Math.pow(60, i), 0);

      let return_data = "00:00:00";
      if (sum !== 0) {
        return_data = this.mixin_seconds_toHHMMSS(sum);
      }

      return return_data;
    },

    chkNoTime(){
      let TIME_CNT = 0;
      for (let i = 0; i<this.CHK_NEWTIME.length;i++){
        if (this.CHK_NEWTIME[i] != ""){
          TIME_CNT = TIME_CNT + 1;
        }
      }
      if (TIME_CNT == 0){
        if (this.SEARCH_NEWTY == "TIME"){
          this.showAlert("조회 유형이 [시간]일 때<br>시간 조회조건이 하나 이상 필요합니다.<br><br>자동으로 [09시]를 선택합니다.");
          this.CHK_NEWTIME = [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "09",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ]
        }
      }
    },
    changeDate(type) {
      if(
        moment(this.dates[0]).isBefore(moment(new Date()).subtract(30, 'days').format('YYYY-MM-DD')) ||
        moment(this.dates[1]).isAfter(moment(new Date()).subtract(0, 'days').format('YYYY-MM-DD'))
      ){
        this.common_alert('검색 기간은 최근 1개월까지 선택 가능합니다.');
        const date = moment().format("YYYY-MM-DD");
        this.dates[0] = date;
        this.dates[1] = date;
        this.from = date;
        this.to = date;
        return;
      }

      if(type == 'start') {
        if (moment(this.dates[0]).isBefore(moment(this.dates[1]).subtract(30, 'days').format('YYYY-MM-DD'))) {
          this.common_alert('검색 기간은 최대 1개월까지 선택 가능합니다.');
          const date = moment().format("YYYY-MM-DD");
          this.dates[0] = date;
          this.dates[1] = date;
          this.from = date;
          this.to = date;
          return;
        } else {
          this.from = this.dates[0];
        }
      } else if (type == 'end') {
        if (moment(this.dates[1]).isAfter(moment(this.dates[0]).add(30, 'days').format('YYYY-MM-DD'))) {
          this.common_alert('검색 기간은 최대 1개월까지 선택 가능합니다.');
          const date = moment().format("YYYY-MM-DD");
          this.dates[0] = date;
          this.dates[1] = date;
          this.from = date;
          this.to = date;
          return;
        } else {
          this.to = this.dates[1];
        }
      }
    },
  },

  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
  },
};
</script>

<style></style>
